<template>
  <AdminToast/>
  <div class="grid">
    <div class="col-12">
      <Panel>
        <template #header>
          <b>Yönetici Bilgileri</b>
        </template>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <label for="name">Yönetici Adı</label>
            <InputText id="name" type="text" v-model="dataItem.name"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="surName">Yönetici Soyadı</label>
            <InputText id="surName" type="text" v-model="dataItem.surName"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="email">Yönetici Emai</label>
            <InputText id="email" type="text" v-model="dataItem.email"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="password">Yönetici Şifre</label>
            <InputText id="password" type="text" v-model="dataItem.password"/>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Kaydet" class="p-button-success p-button-raised p-button-rounded" @click="save"></Button>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Sil" class="p-button-danger p-button-raised p-button-rounded" @click="this.deleteConfirmationDialogVisibility= true" v-if="managerUserId>0"></Button>
          </div>
        </div>
      </Panel>
      <Dialog
          v-model:visible="deleteConfirmationDialogVisibility"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
          <span>Bu Yöneticiyi silmek istediğinize eminmisiniz?</span>
        </div>
        <template #footer>
          <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteConfirmationDialogVisibility = false"
          />
          <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedData"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import AdminToast from "@/components/shared/toast/adminToast";
import {showErrorMessage} from "@/components/shared/toast/toastFunction";
import {showSuccessMessage} from "../../components/shared/toast/toastFunction";
import ManagerUserService from "../../services/managerUserService";

export default {
  _managerUserService: null,
  components: {AdminToast},
  created() {
    this._managerUserService = new ManagerUserService();
    this.managerUserId = this.$route.params.managerUserId;
  },
  async mounted() {
    await this.getUser(this.managerUserId);
  },

  data() {
    return {
      returnField:"",
      deleteConfirmationDialogVisibility:false,
      managerUserId:0,
      dataItem: {
        name: "",
        isActive: "",
      }
    }
  },
  methods: {
    async save() {
      const updateResponse = await this._managerUserService.updateManager(this.managerUserId, this.dataItem);
      if(updateResponse.isSuccess)
      {
        showSuccessMessage(this, "Yönetici başarı ile güncellendi");
      }
      else {
        showErrorMessage(this, "Yönetici güncellemede sorun ile karşılaşıldı");
      }
    },

    async deleteSelectedData() {
      this.deleteConfirmationDialogVisibility = false;
      const deleteResponse = await this._managerUserService.deleteById(this.managerUserId);
      if (deleteResponse.isSuccess) {
        setTimeout( async () => await this.$router.push({name: "managerUserList"}), 2000);
        showSuccessMessage(this, deleteResponse.message);


      } else {
        showErrorMessage(this, deleteResponse.message);
      }
    },
    async getUser(managerUserId) {
      let getResponse = await this._managerUserService.getManagerUserById(managerUserId);
      if(getResponse.isSuccess) {
        this.dataItem.name = getResponse.data.name;
        this.dataItem.surName = getResponse.data.surName;
        this.dataItem.email = getResponse.data.email;
        this.dataItem.password = "";
      }
      else {
        showErrorMessage(this, getResponse.message);
      }
    }
  }
}
</script>